import React from 'react'
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from './context/UserContext'
import { MenuContext } from "./context/MenuContext"
import HeaderAndMenu from './components/HeaderAndMenu'

const tempMonthArr = [1,2,3,4,5,6,7,8,9,10,11,12] 
var tempYearArr = []
function setSelectYearOptions() {
  tempYearArr=[]
  let nowDate = new Date()
  for(var y=nowDate.getFullYear() ; y>=2022 ; y--){
    tempYearArr.push(y)
  }
}

export default function Billing() {
  const { t } = useTranslation();

  const { userInfo } = useContext(UserContext)
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [ year, setYear ] = useState('all')
  const [ month, setMonth ] = useState('all')
  const [ billsArr, setBillsArr] = useState([])
  const [ loading, setLoading ] = useState(0)
  const [ page, setPage ] = useState(0);
  const [ pageInfo, setPageInfo ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(15);
  const [ userData, setUserData ] = useState({})

  const columns = [
    { id: 'year_month', label: t('payment_period'), minWidth: 100 },
    { id: 'paid_date', label: t('payment_date'), minWidth: 100 },
    { id: 'income', label: t('amount_submitted'), minWidth: 100 },
    { id: 'account', label: t('attachment'), minWidth: 100 },
    { id: 'file', label: t('show_all'), minWidth: 150 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoading(true)
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSetDefault = () => {
    setYear('all')
    setMonth('all')
  }

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  useEffect(()=>{
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };
    fetch('https://api.futurekey.org/api/users/'+userInfo.id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK')  {
        setUserData(data.data)
      }else{
        console.log(data)
      }
    })
  },[userInfo])

  useEffect (() => {
      setLoading(true)
      setSelectYearOptions()

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      var filter = '?type=匯款&user_id='+userInfo.id+
        (year==='all'?'':('&year='+year))+
        (month==='all'?'':('&month='+month))

      fetch('https://api.futurekey.org/api/billing_notices'+filter, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setBillsArr(data.data.billing_notices)
          setPageInfo(data.data.meta.total)
          setLoading(false)
        }else{
          console.log(data.message)
        }
      })
  },[userInfo, year, month])

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle={t('payment_history')}/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
            <Toolbar className='fixToolbar' />
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Card sx={{ minWidth: 275, mb: 2 }}>
                <CardContent>
                  <Box sx={{p: 2, border:'1px #ccc solid'}}>
                    <Typography>{t('invoice_1')}</Typography>
                    <Typography>{t('invoice_2')}</Typography>
                    <Typography>{t('invoice_3')}</Typography>
                    <Typography>{t('invoice_4')}</Typography>
                    <Typography>{t('invoice_5')}</Typography>
                    <Typography>{t('invoice_6')}</Typography>
                    <Typography>{t('invoice_7')}</Typography>
                    <Typography>{t('invoice_note_title')}</Typography>
                    <Typography>{t('invoice_note')}</Typography>
                  </Box>
                  <Grid container sx={{alignItems: 'center'}}>
                    <Grid item xs={8}>
                      <Grid container sx={{alignItems: 'center'}}>
                        <Grid item mr={2}>
                          <FormControl sx={{ minWidth: 100 }}>
                            <InputLabel id="year-select-helper-label">{t('year')}</InputLabel>
                            <Select
                              size="small"
                              labelId="year-select-label"
                              id="year_select"
                              name="year_select"
                              label={t('year')}
                              value={year}
                              onChange={handleYearChange}
                            >
                              <MenuItem value='all'>{t('all')}</MenuItem>
                              {
                                tempYearArr.map((y)=>(
                                  <MenuItem value={y} key={y}>{y}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item mr={2}>
                          <FormControl sx={{ minWidth: 100 }}>
                            <InputLabel id="month-select-helper-label">{t('month')}</InputLabel>
                            <Select
                              size="small"
                              labelId="month-select-label"
                              id="month_select"
                              name="month_select"
                              label={t('month')}
                              value={month}
                              onChange={handleMonthChange}
                            >
                              <MenuItem value='all'>{t('all')}</MenuItem>
                              {
                                tempMonthArr.map((m)=>(
                                  <MenuItem value={m} key={m}>{m}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item mr={2}>
                          <Button onClick={handleSetDefault} sx={{ minWidth: 100 }} variant='outlined'>{t('show_all')}</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <TableContainer>
                  <Table aria-label="table" size="small">
                    <TableHead>
                      <TableRow>
                        {
                          columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align="center"
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))
                        }

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        loading 
                        ? 
                        <TableRow>
                          <TableCell align="center" colSpan={8}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                        :
                        (
                          billsArr.length === 0 ?
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              {t('no_data')}
                            </TableCell>
                          </TableRow>
                        :
                        billsArr.length > 0 &&
                        billsArr.map((row, index) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell align="center">{row.year}-{row.month}</TableCell>
                            <TableCell align="center"><Moment format="YYYY-MM-DD">{row.paid_date}</Moment></TableCell>
                            <TableCell align="center">
                              {userData.currency && userData.currency.code}
                              {'$ '}
                              {(row.income).toLocaleString('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 2})}
                            </TableCell>
                            <TableCell align="left">
                              {
                                userData.bank_accounts && userData.bank_accounts.map((a)=>(
                                  a.currency.id===userData.currency.id &&
                                  <React.Fragment key={a.id}>
                                    <Typography style={{margin:0}}>{a.account_name}</Typography>
                                    <Typography style={{margin:0}}>{a.bank_code}</Typography>
                                  </React.Fragment>
                                ))
                              }
                            </TableCell>
                            <TableCell align="center">{
                              row.file &&
                                <IconButton color="info" aria-label="download">
                                  <a href={row.file} style={{color: "unset"}} target="_blank" rel="noreferrer">
                                    <DownloadIcon />
                                  </a>
                                </IconButton>
                              }
                            </TableCell>
                          </TableRow>
                        ))
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[15, 30]}
                  component="div"
                  count={pageInfo}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>
          </Box>
        </Box>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}

