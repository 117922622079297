import React from 'react'
import { useState, useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme'
import ExcelJs from "exceljs";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import { UserContext } from './context/UserContext'
import { OpenContext } from "./context/OpenContext"
import { AlertContext } from './context/AlertContext'
import { MenuContext } from "./context/MenuContext"
import HeaderAndMenu from './components/HeaderAndMenu'
import EditAccountingDialog from './components/EditAccountingDialog'
import ViewAccountingDetailDialog from './components/ViewAccountingDetailDialog'
import StickyNote2Icon from '@mui/icons-material/StickyNote2';

const tempMonthArr = [1,2,3,4,5,6,7,8,9,10,11,12] 
const tempYearArr = []
function setSelectYearOptions() {
  let nowDate = new Date()
  for(var y=nowDate.getFullYear() ; y>=2022 ; y--){
    tempYearArr.push(y)
  }
}

const receivableColumns = [
  { id: 'platform', label: '廣告平台', minWidth: 100 },
  { id: 'income', label: '收益', minWidth: 100 },
  { id: 'receipt_date', label: '發票日期', minWidth: 120 },
  { id: 'receipt_num', label: '發票編號', minWidth: 100 },
  { id: 'domain', label: '收款日', minWidth: 120 },
  { id: 'note', label: '備註', minWidth: 70 },
  { id: 'action', label: '操作', minWidth: 250 }
];
const payableColumns = [
  { id: 'platform', label: '網站主', minWidth: 100 },
  { id: 'income', label: '收益', minWidth: 100 },
  { id: 'receipt_date', label: '發票日期', minWidth: 120 },
  { id: 'receipt_num', label: '發票編號', minWidth: 100 },
  { id: 'domain', label: '匯款日', minWidth: 120 },
  { id: 'note', label: '備註', minWidth: 70 },
  { id: 'action', label: '操作', minWidth: 250 }
];
function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function Accounting() {
  const { userInfo } = useContext(UserContext)
  const [ menuOpen, setMenuOpen ] = useState(true);
  const [ alertOpen, setAlertOpen ] = useState({
    open: false,
    message: ''
  });
  const [ type, setType ] = useState('receivable')
  const nowDate = new Date()
  const [ year, setYear ] = useState('')
  const [ month, setMonth ] = useState('')
  const [ currencyArr, setCurrencyArr ] = useState([])
  const [ currency, setCurrency ] = useState('')
  const [ keyword, setKeyword ] = useState('')
  const [ billsArr, setBillsArr] = useState([])
  const [ loading, setLoading ] = useState(0)
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(15);
  const [ pageInfo, setPageInfo ] = useState(0);
  const [ dialogTitle, setDialogTitle ] = useState('');
  const [ dialogOpen, setDialogOpen ] = useState({
    edit: false,
    view: false
  })
  const [ editBill, setEditBill ] = useState('')
  const [ viewBill, setViewBill ] = useState('')
  const [ downloadBill, setDownloadBill ] = useState({
    currency: {code:''},
    income: '',
    platform: {name:''},
  })
  const payExcel = new ExcelJs.Workbook();
  const getExcel = new ExcelJs.Workbook();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoading(true)
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setKeyword(event.target.value);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    if(event.target.value===''){
      fetch('https://api.futurekey.org/api/billings?type='+ (type==='receivable'?'請款':"匯款") + '&currency_id=' + currency, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setBillsArr(data.data.bills)
          setPageInfo(data.data.meta.total)
          setLoading(false)
        }else{
          console.log(data.message)
        }
      })
    }else{
      fetch('https://api.futurekey.org/api/billings?type='+ (type==='receivable'?'請款':"匯款") + '&currency_id=' + currency + '&keyword=' + event.target.value, requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          setBillsArr(data.data.bills)
          setPageInfo(data.data.meta.total)
          setLoading(false)
        }else{
          console.log(data.message)
        }
      })
    }
  }

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleDownloadCSV = (event, id) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token }
    };

    fetch('https://api.futurekey.org/api/billings/' + id, requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK')  {
        setDownloadBill(data.data)
        setLoading(false)
      }else{
        setLoading(true)
        console.log(data)
      }
    })
  }

  useEffect(()=>{
    if(downloadBill.income!==''){
      var column=[]
      var data=[]
      if(type==="receivable"){
        const sheet = getExcel.addWorksheet(year+'年'+month+'月 帳款明細', {properties: {defaultColWidth: 15}});
        column = [{name:'項目'}, {name:'收益'+downloadBill.currency.code}]
        // eslint-disable-next-line array-callback-return
        downloadBill.details.map((d)=>{
          if(d.items.length>0){
            data.push([d.name, d.total])
            // eslint-disable-next-line array-callback-return
            d.items.map((i)=>{
              data.push([i.name, i.subtotal])
            })
          }
        })
        data.push(['當月總計', parseFloat(downloadBill.income)])
        sheet.addTable({
          name: 'reportCSV',
          ref: 'A1',
          columns: column,
          rows: data
        });
  
        getExcel.xlsx.writeBuffer().then((content) => {
          const link = document.createElement("a");
          const blobData = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8;"
          });
          link.download = downloadBill.platform.name + year+'年'+month+'月 帳款明細.xlsx';
          link.href = URL.createObjectURL(blobData);
          link.click();
        });
      }else{
        const sheet = payExcel.addWorksheet(year+'年'+month+'月 帳款明細', {properties: {defaultColWidth: 15}});

        column = [{name:'項目'}, {name:'收益'+downloadBill.currency.code}]
        // eslint-disable-next-line array-callback-return
        downloadBill.details.map((d)=>{
          if(d.items.length>0){
            data.push([d.name, d.total])
            // eslint-disable-next-line array-callback-return
            d.items.map((i)=>{
              data.push([i.name, i.subtotal])
            })
          }
        })

        data.push(['當月總計', parseFloat(downloadBill.income)])
        sheet.addTable({
          name: 'reportCSV',
          ref: 'A1',
          columns: column,
          rows: data
        });
  
        payExcel.xlsx.writeBuffer().then((content) => {
          const link = document.createElement("a");
          const blobData = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8;"
          });
          link.download = downloadBill.user.username + year+'年'+month+'月 帳款明細.xlsx';
          link.href = URL.createObjectURL(blobData);
          link.click();
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[downloadBill])

  useEffect(()=>{
    setSelectYearOptions()
    setYear(nowDate.getFullYear())
    setMonth(nowDate.getMonth())

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
    };

    fetch('https://api.futurekey.org/api/currencies', requestOptions)
    .then(response => response.json())
    .then(data => {
      if(data.message==='OK'){
        setCurrencyArr(data.data)
        setCurrency('1')
      }else{
        console.log(data.message)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect (() => {
    if(!dialogOpen.edit && !dialogOpen.view && month && year && currency){
      setLoading(true)

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userInfo.token},
      };

      fetch('https://api.futurekey.org/api/billings?type='+ (type==='receivable'?'請款':"匯款") + '&currency_id=' + currency + '&year=' + year + '&month=' + month+ '&per_page=' + rowsPerPage + '&page=' + (page+1), requestOptions)
      .then(response => response.json())
      .then(data => {
        if(data.message==='OK'){
          console.log(data.data.bills)
          setBillsArr(data.data.bills)
          setPageInfo(data.data.meta.total)
          setLoading(false)
        }else{
          console.log(data.message)
        }
      })
      setTimeout(() => {
        setAlertOpen({
          open: false,
          message: ''
        })
      }, 3000);
    }
  },[userInfo, type, currency, dialogOpen, year, month, rowsPerPage, page])

  return (
    <ThemeProvider theme={theme}>
      <MenuContext.Provider value={{menuOpen, setMenuOpen}}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <HeaderAndMenu mainTitle="帳款紀錄"/>
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
            <Toolbar className='fixToolbar' />
            <Snackbar open={alertOpen.open} TransitionComponent={TransitionDown} anchorOrigin={{vertical: 'top', horizontal:'right' }}>
              <Alert severity="success" open={false}>{alertOpen.message}</Alert>
            </Snackbar>
            <Container className='mainContainer' maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent> 
                  <Grid container sx={{alignItems: 'center'}}>
                    <Grid item mr={2}>
                      <Button onClick={() => setType('receivable')} sx={{ minWidth: 130 }} variant={ type==='receivable' ? 'contained' : 'outlined' }>應收帳款</Button>
                    </Grid>
                    <Grid item mr={2}>
                      <Button onClick={() => setType('payable')} sx={{ minWidth: 130 }} variant={ type==='payable' ? 'contained' : 'outlined' }>應付帳款</Button>
                    </Grid>
                  </Grid>
                  <Grid container sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                    <Grid item xs={8}>
                      <Grid container sx={{alignItems: 'center'}}>
                        <Grid item mr={2}>
                          <FormControl sx={{ minWidth: 130 }}>
                            <InputLabel id="year-select-helper-label">年份</InputLabel>
                            <Select
                              size="small"
                              labelId="year-select-label"
                              id="year_select"
                              name="year_select"
                              label="年份"
                              value={year}
                              onChange={handleYearChange}
                            >
                              {
                                tempYearArr.map((y)=>(
                                  <MenuItem value={y} key={y}>{y}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item mr={2}>
                          <FormControl sx={{ minWidth: 130 }}>
                            <InputLabel id="month-select-helper-label">月份</InputLabel>
                            <Select
                              size="small"
                              labelId="month-select-label"
                              id="month_select"
                              name="month_select"
                              label="月份"
                              value={month}
                              onChange={handleMonthChange}
                            >
                              {
                                tempMonthArr.map((m)=>(
                                  <MenuItem value={m} key={m}>{m}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item mr={2}>
                          <FormControl sx={{ minWidth: 130 }}>
                            <InputLabel id="currency-select-helper-label">幣別</InputLabel>
                            <Select
                              size="small"
                              labelId="currency-select-label"
                              id="currency_select"
                              name="currency_select"
                              label="幣別"
                              value={currency}
                              onChange={handleCurrencyChange}
                            >
                              {
                                currencyArr.map((c)=>(
                                  <MenuItem value={c.id} key={c.id}>{c.name}{c.code}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sx={{alignItems: 'center', textAlign: 'right'}} xs={4}>
                      <TextField
                        sx={{ width: 200 }}
                        id="search_key"
                        placeholder='請輸入關鍵字搜尋'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={keyword}
                        onChange={handleSearchChange}
                        size="small"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 275, mt: 2 }}>
              <TableContainer>
                  <Table aria-label="table" size='small'>
                    <TableHead>
                      <TableRow>
                        {
                          type==='receivable'
                          ? 
                          receivableColumns.map((column) => (
                            <TableCell
                              key={column.id}
                              align="center"
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))
                          : 
                          payableColumns.map((column) => (
                            <TableCell
                              key={column.id}
                              align="center"
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))
                        }

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        loading 
                        ? 
                        <TableRow>
                          <TableCell align="center" colSpan={8}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                        :
                        (
                          billsArr.length === 0 ?
                          <TableRow>
                            <TableCell align="center" colSpan={8}>
                              無相關資料
                            </TableCell>
                          </TableRow>
                        :
                        billsArr.length > 0 &&
                        billsArr.map((row, index) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell align="center">{
                              row.platform
                              ? row.platform.name
                              : row.user.username}
                            </TableCell>
                            <TableCell align="center">{parseFloat(row.income).toLocaleString()}</TableCell>
                            <TableCell align="center">{row.receipt_date}</TableCell>
                            <TableCell align="center">{row.receipt_num}</TableCell>
                            <TableCell align="center">
                              <Moment format="YYYY-MM-DD">{row.created_at}</Moment>
                            </TableCell>
                            <TableCell align="center">
                              {
                                row.note &&
                                <Tooltip disableFocusListener placement="top" title={row.note}>
                                  <IconButton sx={{p:0}} aria-label="show-note">
                                    <StickyNote2Icon />
                                  </IconButton>
                                </Tooltip>
                              }
                            </TableCell>
                            <TableCell align="center">    
                              <Button onClick={()=>{
                                  setEditBill(row.id)
                                  setDialogTitle('編輯帳務資訊')
                                  setDialogOpen({
                                    edit: true,
                                    view: false
                                  })
                                }} variant="text" startIcon={<EditIcon />}>
                                編輯
                              </Button>
                              <Button onClick={()=>{
                                  setViewBill(row.id)
                                  setDialogTitle(row.year+'年'+row.month+'月 帳款明細')
                                  setDialogOpen({
                                    edit: false,
                                    view: true
                                  })
                                }}  variant="text" color='success' startIcon={<RemoveRedEyeIcon />}>查看明細</Button>
                              <Button onClick={(event)=>{handleDownloadCSV(event , row.id)}}  variant="text" color='primary' startIcon={<DownloadIcon />}>下載CSV</Button>
                            </TableCell>
                          </TableRow>
                        ))
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[15, 30]}
                  component="div"
                  count={pageInfo}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Container>
            <OpenContext.Provider value={{dialogOpen, setDialogOpen}}>
              <AlertContext.Provider value={{alertOpen, setAlertOpen}}>
                <EditAccountingDialog title={dialogTitle} id={editBill}/>
                <ViewAccountingDetailDialog title={dialogTitle} id={viewBill}/>
              </AlertContext.Provider>
            </OpenContext.Provider>
          </Box>
        </Box>
      </MenuContext.Provider>
    </ThemeProvider>
  )
}

